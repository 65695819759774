import React, { useEffect, useState } from 'react';
import "./Statistics.css";

const Statistics = () => {
  // State for the animated counter
  const [clientCount, setClientCount] = useState(0);
  const [activeClientCount, setActiveClientCount] = useState(0);
  const [yearCount, setYearCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);

  useEffect(() => {
    // Animation effect for counting
    const clientInterval = setInterval(() => {
      if (clientCount < 3500) {
        setClientCount(clientCount + 1);
      }
    }, 1);

    const activeClientInterval = setInterval(() => {
      if (activeClientCount < 1800) {
        setActiveClientCount(activeClientCount + 1);
      }
    }, 1);

    const yearInterval = setInterval(() => {
      if (yearCount < 12) {
        setYearCount(yearCount + 1);
      }
    }, 100);

    const employeeInterval = setInterval(() => {
      if (employeeCount < 200) {
        setEmployeeCount(employeeCount + 1);
      }
    }, 10);

    // Clean up intervals after the animation completes
    setTimeout(() => {
      clearInterval(clientInterval);
      clearInterval(activeClientInterval);
      clearInterval(yearInterval);
      clearInterval(employeeInterval);
    }, 3000);

  }, [clientCount, activeClientCount, yearCount, employeeCount]);

  return (
    <div className="statistics">
      <div className="stat-item">
        <h3>{clientCount}+</h3>
        <p>Total Clients</p>
      </div>
      <div className="stat-item">
        <h3>{activeClientCount}+</h3>
        <p>Active Clients</p>
      </div>
      <div className="stat-item">
        <h3>{yearCount}+</h3>
        <p>Years of Business</p>
      </div>
      <div className="stat-item">
        <h3>{employeeCount}+</h3>
        <p>Employees</p>
      </div>
    </div>
  );
};

export default Statistics;
