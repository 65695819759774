import React, { useState } from "react";
import "./Service.css";
import { image3 } from "../../assets/index.js";
import Form from '../Form/Form.js';
const UnsecureFunding = () => {
    const [isShowForm, setForm] = useState(false);

    const isToggleForm = () => {
        setForm(!isShowForm);
    };
    const formdetails = {
        fullName: '',
        phone: '',
        loantype: '',

    }
    function handlForm() {
        setForm(true);

    }
    return (
        <>
            <Form show={isShowForm} isToggleForm={isToggleForm} formRequierment={formdetails} formIndex={2} />

            <div className="service5" data-aos="fade-up">
               

                <div className="service-content">
                    <div className="services5">
                        <h2 className="service-title"> Unsecure Funding</h2>
                        <p>
                            Private funding is a way for businesses to raise money for various purposes, such as startup costs, growth, research and development, and new equipment.         </p>
                    </div>
                    <button className="get-started-btn" onClick={handlForm}>Check Now</button>
                </div>
                <div className="service-image">
                    <img src={image3} alt="Finance Advice" />
                </div>

            </div>
        </>
    );
};

export default UnsecureFunding;
