import React, { useState } from "react";
import "./Service.css";
import { service1 } from "../../assets/index.js";
import Form from '../Form/Form.js';

const CoprateFunding = () => {
  const [isShowForm, setForm] = useState(false);
  
      const isToggleForm = () => {
          setForm(!isShowForm);
      };
      const formdetails = {
          fullName: '',
          phone: '',
          loantype: '',
  
      }
      function handlForm() {
          setForm(true);
  
      }
  return (
    <>
      <Form show={isShowForm} isToggleForm={isToggleForm} formRequierment={formdetails} formIndex={2} />

      <div className="service3" data-aos="fade-up">
        
        <div className="service-content">
          <div className="services3">
            <h2 className="service-title"> Corporate Funding</h2>
            <p>
              Corporate funding services encompass a range of financial solutions tailored to meet the capital requirements and strategic objectives of businesses.          </p>
          </div>
          <button className="get-started-btn " onClick={handlForm}>Check Now</button>
        </div>
        <div className="service-image">
          <img src={service1} alt="Finance Advice" />
        </div>

      </div>
    </>
  );
};

export default CoprateFunding;
