import React from "react";
import "./Financial.css";
import { financial } from "../../assets/index.js";
import { IoMdPhonePortrait } from "react-icons/io";

const Financial = () => {
  const features = [
    {
      id: 1,
      icon: <IoMdPhonePortrait />,
      title: "Fast Approval",
      description: "Get fast approval loans for your urgent financial needs now.",
      color: "purple",
    },
    {
      id: 2,
      icon: <IoMdPhonePortrait />,
      title: "Less Documents",
      description:
        "Minimal paperwork, maximum convenience hassle-free loan processing.",
      color: "green",
    },
  ];

  const headerData = {
    title: "Choose Us To Improve",
    highlight: "Your Financial Condition",
    description:
      "Choose us to transform your financial outlook. With tailored solutions and expert guidance, we're dedicated to empowering your journey towards stability, growth, and prosperity. Your success starts here.",
  };

  const illustration = "illustration.png"; // Replace with your image path

  return (
    <div className="container">
      

      {/* Features and Illustration Section */}
      <div className="features-illustration">
        <div className="features">
          {/* Header Section */}
      <div className="header">
        <h1>
          {headerData.title} <br />
          <span className="highlight">{headerData.highlight}</span>
        </h1>
        <p className="description1">{headerData.description}</p>
      </div>
          {features.map((feature) => (
            <div key={feature.id} className="feature-card">
              <div className={`icon-circle ${feature.color}`}>
                {feature.icon}
              </div>
              <div className="feature-text">
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Illustration Section */}
        <div className="illustration">
          <img src={financial} alt="Illustration" />
        </div>
      </div>
    </div>
  );
};

export default Financial;
